<template>
	<Layout>
		<Header>
			<b-button v-if="permissionEnabled('ratings', 'create')" type="is-primary create" rounded @click="createRating()">
				<svg-icon icon="plus" class="icon is-small"></svg-icon>
				<span>Add</span>
			</b-button>
		</Header>
		<section class="filter">
			<div class="filter__wrapper">
				<b-field>
					<b-input placeholder="Search" type="search" icon-right="magnify" @input="findByName" v-model="rating.name"></b-input>
				</b-field>
			</div>
		</section>
		<Error v-if="errored" :icon="true" :back="true" />
		<Results v-if="!loading && !errored && ratings.length <= 0" />
		<div v-if="loading" class="columns is-multiline">
			<div v-for="n in pagination" :key="n" class="column is-12-mobile is-6-tablet is-4-desktop">
				<Placeholder />
			</div>
		</div>
		<transition-group name="filtering" class="filtering columns is-multiline" tag="div">
			<div v-for="r in ratings" :key="r.id" class="column is-12-mobile is-6-tablet is-4-desktop">
				<article class="block" :class="{ pointer: returnUser() == 'user' }" @click="preStartRating(r.id, r.status_name)">
					<div class="block__content">
						<h3 class="block__name">{{ r.name }}</h3>
						<p class="block__address">{{ format(r.start) }} to {{ format(r.end) }}</p>
					</div>
					<div class="block__status" v-if="r.icon != 'blank'">
						<b-tooltip :label="tooltip(r.icon)" position="is-top">
							<b-icon :icon="icon(r.icon)" class="icon"></b-icon>
						</b-tooltip>
					</div>
					<Trigger :id="r.id" />
				</article>
			</div>
		</transition-group>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Icon from '@/components/Icon'
import Placeholder from '@/components/placeholders/Role'
import Trigger from '@/components/triggers/Rating'
import Error from '@/components/Error'
import Results from '@/components/Results'
import Rating from '@/components/modals/Rating'
import Rate from '@/components/modals/Rate'
import Dealers from '@/components/modals/SelectDealer'
import Categories from '@/components/modals/SelectCategory'
import BillingWorksheet from '@/components/modals/BillingWorksheet'
import { create, update, rate } from '@/mixins/modal'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { ModalProgrammatic as Modal } from 'buefy'
import { successToast, errorToast } from '@/mixins/toast'
import { mapGetters } from 'vuex'
import '@/mixins/date'
import actionUrl from '@/mixins/actionurl'

export default {
	components: {
		Layout,
		Header,
		Placeholder,
		Error,
		Results,
		Trigger,
		'svg-icon': Icon
	},
	mixins: [
		actionUrl
	],
	data() {
		return {
			ratingIdTemp: null,
			exporting: false,
			errored: false,
			loading: true,
			pagination: 15,
			labels: {
				require_payment: 'Awaiting Billing',
				waiting: 'Under Evaluation',
				're-evaluated': 'Reevaluated',
				rated: 'Awaiting Conclusion',
				contested: 'In Dispute',
				accepted: 'Done',
				note_received: 'Debit Note Sent',
				waiting_payment: 'Awaiting Payment',
				payment_done: 'Payment Done'
			},
			iconStatus: {
				require_payment: 'cash-plus',
				waiting: 'clipboard-text-play-outline',
				're-evaluated': 'clipboard-text-multiple-outline',
				rated: 'clipboard-check-outline',
				contested: 'clipboard-text-off-outline',
				accepted: 'check',
				note_received: 'cash',
				waiting_payment: 'cash-fast',
				payment_done: 'cash-check'
			},
			ratings: [],
			rating: {
				name: ''
			},
			user: {
				dealers: []
			}
		}
	},
	methods: {
		ratingWaiting() {
			errorToast('Awaiting start date for this <strong>evaluation</strong>.')
		},
		getParams() {
			let url = location.href.split('/')
			return {
				params: url[4],
				type: url.find(el => el === url[4]),
				id: url[5]
			}
		},
		getUrlParams() {
			const params = this.getParams()

			switch (params.type) {
				case 'create':
					this.createRating()
					break
				case 'rate':
					if (params.id.includes('-')) {
						this.startRatingByNotification(params.id)
					} else {
						this.startRating(params.id)
					}
					break
				case 'edit':
					this.updateRating(params.id)
			}
		},
		icon(indexIcons) {
			return this.iconStatus[indexIcons]
		},
		tooltip(indexStatus) {
			return this.labels[indexStatus]
		},
		createRating() {
			if (this.permissionEnabled('ratings', 'create')) {
				create('ratings', Rating, 'New')
			}
		},
		updateRating(id) {
			if (this.permissionEnabled('ratings', 'edit')) {
				update('ratings', id, Rating, 'Edit')
			}
		},
		async getDealers(id) {
			try {
				const response = await Api.get(`dealer/get-dealers-for-manager/${id}`)
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.user.dealers = data
					this.loading = false
				} else {
					this.errored = true
				}
			} catch (e) {
				this.errored = true
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		returnUser() {
			return this.$store.state.user.user !== null ? this.$store.state.user.user.role.name : ''
		},
		preStartRating(id, ratingStatus) {
			if (ratingStatus == 'waiting' && this.returnUser() !== 'master') {
				this.ratingWaiting()
			} else if (this.returnUser() == 'user') {
				this.startRating(id)
			}
		},
		startRatingByNotification(ids) {
			let values = ids.split('-')
			let dealerId = values[0]
			let ratingId = values[1]

			this.getDealers(ratingId).then(() => {
				const dealer = this.user.dealers.find(d => d.id == dealerId)

				if (this.user.dealers.length == 0) {
					errorToast('You <strong>don\'t have</strong> countries assigned.')
				} else {
					if ((dealer.status === 'waiting' || dealer.status === 'require_payment') && this.returnUser() === 'user') {
						errorToast('Awaiting <strong>evaluation</strong>.')
						return false
					}

					this.openRating(ratingId, { dealerId: dealerId, user: this.$store.state.user.user })
				}
			})
		},
		startRating(id) {
			this.getDealers(id).then(() => {
				let dealers = this.user.dealers
				if (dealers.length > 1) {
					Modal.open({
						component: Dealers,
						scroll: 'keep',
						customClass: 'is-sm',
						trapFocus: true,
						props: {
							id: id,
							dealers: dealers,
							user: this.$store.state.user.user
						}
					})
				} else if (dealers.length === 1) {
					if ((dealers[0].status === 'waiting' || dealers[0].status === 'require_payment') && this.returnUser() === 'user') {
						errorToast('Awaiting <strong>evaluation</strong>.')
						return false
					}
					this.openRating(id, { dealerId: dealers[0].id ?? null, user: this.$store.state.user.user })
				} else {
					errorToast('You <strong>don\'t have</strong> countries assigned.')
				}
			})
		},
		rateCategories(id) {
			Modal.open({
				component: Categories,
				scroll: 'keep',
				customClass: 'is-sm',
				trapFocus: true,
				props: {
					id: id,
					//categories: categories,
					user: this.$store.state.user.user
				}
			})
		},
		billingRating(id) {
			this.openRating(id, { billing: true, user: this.$store.state.user.user })
		},
		openRating(id, optionalProps) {
			rate(this, 'ratings', id, Rate, { user: this.$store.state.user.user, ...optionalProps })
		},
		sendRating(r) {
			console.log(r)
		},
		async getAllRatings() {
			try {
				const response = await Api.get('rating/findAll')
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.ratings = data
					this.loading = false
				} else {
					this.errored = true
				}
			} catch (e) {
				this.errored = true
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		chooseDealer(id) {
			this.exporting = true
			this.getDealers(id).then(() => {
				let dealers = this.user.dealers
				if (dealers.length > 1) {
					Modal.open({
						component: Dealers,
						scroll: 'keep',
						customClass: 'is-sm',
						trapFocus: true,
						props: {
							id: id,
							dealers: dealers,
							user: this.$store.state.user.user,
							type: 'export'
						}
					})
				} else {
					errorToast('You <strong>don\'t have</strong> countries assigned.')
				}
				this.exporting = false
			})
		},
		async findByName() {
			try {
				const empty = /^\s*$/
				if (!empty.test(this.rating.name)) {
					const response = await Api.post('rating/findByName', {
						name: this.rating.name
					})
					const { status } = response
					if (status === 200) {
						const { data } = response
						this.ratings = data
					}
				} else {
					await this.getAllRatings()
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		}
	},
	mounted() {
		this.getAllRatings()
		this.getUrlParams()

		eventHub.$on('reload-ratings', () => {
			this.getAllRatings()
		})
		eventHub.$on('start-rating', obj => {
			this.startRating(obj.id)
		})
		eventHub.$on('rate-categories', obj => {
			this.rateCategories(obj.id)
		})
		eventHub.$on('open-rating', obj => {
			const currentIndexDealer = this.user.dealers.findIndex((d) => d.id == obj.dealerId)
			const maxIndexDealer = (this.user.dealers.length - 1)
			this.ratingIdTemp = obj.ratingId

			this.openRating(obj.ratingId, {
				dealerId: obj.dealerId,
				criteriaId: obj.criteriaId,
				groupId: obj.groupId,
				currentIndexDealer: currentIndexDealer,
				maxIndexDealer: maxIndexDealer
			})
		})
		eventHub.$on('edit-rating', obj => {
			this.updateRating(obj.id)
		})
		eventHub.$on('billing-rating', obj => {
			this.billingRating(obj.id)
		})
		eventHub.$on('billing-worksheet', obj => {
			Modal.open({
				component: BillingWorksheet,
				scroll: 'keep',
				customClass: 'is-sm',
				trapFocus: true,
				props: {
					id: obj.id
				}
			})
		})
		eventHub.$on('delete-rating', obj => {
			this.$buefy.dialog.alert({
				size: 'is-delete',
				type: 'is-outlined is-primary',
				title: 'Atenção!',
				message: '<span>Do you really want to <br><strong>remove</strong> this evaluation?</span> <small>This action cannot be undone and impacts direcly on the functioning of the system.</small>',
				canCancel: true,
				focusOn: 'cancel',
				cancelText: 'No',
				confirmText: 'Yes',
				onConfirm: async () => {
					try {
						const response = await Api.delete(`rating/destroy/${obj.id}`)
						const { status } = response
						if (status === 200) {
							successToast('The evaluation has been <strong>removed</strong> successfully!')
							eventHub.$emit('reload-ratings')
						}
					} catch (e) {
						console.log(e)
					}
				}
			})
		}),
		eventHub.$on('list-contests', obj => {
			this.$router.push({ name: 'Contests', params: { id: obj.id } }).catch(() => {})
		}),
		eventHub.$on('previous-dealer', obj => {
			let indexPrevious = (obj.currentIndexDealer - 1)
			const dealer = this.user.dealers[indexPrevious]

			eventHub.$emit('open-rating', { dealerId: dealer.id, ratingId: this.ratingIdTemp })
		})
		eventHub.$on('next-dealer', obj => {
			let indexNext = (obj.currentIndexDealer + 1)
			const dealer = this.user.dealers[indexNext]

			eventHub.$emit('open-rating', { dealerId: dealer.id, ratingId: this.ratingIdTemp })
		})
		eventHub.$on('export-rating', obj => {
			this.chooseDealer(obj.id)
		})
	},
	destroyed() {
		eventHub.$off('reload-rating')
		eventHub.$off('start-rating')
		eventHub.$off('open-rating')
		eventHub.$off('edit-rating')
		eventHub.$off('delete-rating')
		eventHub.$off('rate-categories')
		eventHub.$off('billing-rating')
		eventHub.$off('previous-dealer')
		eventHub.$off('next-dealer')
		eventHub.$off('export-rating')
		eventHub.$off('billing-worksheet')
	},
	computed: {
		...mapGetters('user', ['permissionEnabled'])
	}
}
</script>
