<template>
    <div>
		<header class="modal-card-head">
			<h4 class="modal-card-title">
				<span>
					<strong>Billing</strong> Sheet
				</span>
			</h4>
		</header>
		<div class="modal-card-body modal-rounded">
			<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
			<div class="columns is-mobile">
				<div class="column is-half-desktop">
					<b-button class="is-info is-outlined btn-billing" @click="downloadTemplate()" expanded>
						<svg-icon icon="download" class="icon"></svg-icon>
						<span><strong>Export</strong></span>
					</b-button>
				</div>
				<div class="column is-half-desktop">
					<b-upload class="button is-success is-outlined btn-billing" accept=".xls" @input="fileLoading" v-model="worksheet" ref="files" expanded>
						<svg-icon icon="import" class="icon"></svg-icon>
						<span><strong>Import</strong></span>
					</b-upload>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Api from '@/services/api'
import Icon from '@/components/Icon'
import { successToast, errorToast } from '@/mixins/toast'

export default {
	props: {
		id: {
			type: [Number, String],
			required: false
		}
	},
	components: {
		'svg-icon': Icon
	},
	data() {
		return {
			isOpening: false,
			worksheet: null
		}
	},
	methods: {
		downloadTemplate() {
				this.isOpening = true
				Api.post(`rating/export-rate-billing`, { ratingId: this.id }, { responseType: 'blob' })
			.then((res) => {
				let blob = new Blob([res.data], { type: res.data.type })
				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = 'billing.xls'
				link.click()
			}).catch((err) => {
				console.log(err)
				errorToast('An <strong>error</strong> has occurred while generating the model.')
			}).finally(() => {
				this.isOpening = false
				this.$emit('close')
			})
		},
		fileLoading(e) {
			this.isOpening = true

			const formData = new FormData()
			formData.append('file', e)
			formData.append('ratingId', this.id)

			Api.post(`rating/upload-rate-billing`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then((res) => {
				console.log(res)
				successToast('Billing has been <strong>registered</strong> successfully!')
			}).catch((err) => {
				console.log(err)
				errorToast('An <strong>error</strong> has occurred while uploading the file.')
			}).finally(() => {
				this.isOpening = false
				this.$emit('close')
			})
		}
	}
}
</script>
